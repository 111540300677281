export const COLORS = [
    // group 1
    'pink',
    'blue',
    'green',
    'amber',
    // group 2
    'cyan',
    'lime',
    'deepOrange',
    'deepPurple',
    // group 3
    'teal',
    'yellow',
    'red',
    'indigo',
    // group 4
    'orange',
    'purple',
    'lightBlue',
    'lightGreen'
];